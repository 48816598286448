import React, { useContext, useState, useEffect } from "react"
import axios from "axios"

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  // Beer Special API calls
  const [dealLoading, setDealLoading] = useState(true)
  const [dealBeer, setDealBeer] = useState([])
  const [growlerLoading, setGrowlerLoading] = useState(true)
  const [growlerBeer, setGrowlerBeer] = useState([])
  const [instagramLoading, setInstagramLoading] = useState(true)
  const [instagramImages, setInstagramImages] = useState([])

  const getDeals = async () => {
    try {
      // Fetch Deal Beer from Untappd
      const response = await axios("/api/untappd?menu=deals")
      const loadedDealBeer = response.data.items
      setDealBeer(loadedDealBeer)
      setDealLoading(false)
    } catch (error) {
      console.error(error)
      setDealBeer("error")
      setDealLoading(false)
    }
  }

  const getGrowlerBeer = async () => {
    try {
      // Fetch Growler Beer from Untappd
      const response = await axios("/api/untappd?menu=growler")
      const loadedGrowlerBeer = response.data.items
      setGrowlerBeer(loadedGrowlerBeer)
      setGrowlerLoading(false)
    } catch (error) {
      console.error(error)
      setGrowlerBeer("error")
      setGrowlerLoading(false)
    }
  }

  const getImages = async () => {
    try {
      // Fetch most recent Instagram images/links
      const response = await axios.get("/api/insta")
      // Filter out video posts
      const loadedImages = response.data.data.filter(
        image => image.media_type !== "VIDEO"
      )
      // Change second argument of slice to change number of images in grid
      setInstagramImages(loadedImages.slice(0, 6))
      setInstagramLoading(false)
    } catch (error) {
      console.error(error)
      setInstagramImages("error")
      setInstagramLoading(false)
    }
  }

  useEffect(() => {
    getDeals()
    getGrowlerBeer()
    getImages()
  }, [])

  return (
    <AppContext.Provider
      value={{
        dealBeer,
        growlerBeer,
        instagramImages,
        isMobileMenuOpen,
        setIsMobileMenuOpen,
        dealLoading,
        growlerLoading,
        instagramLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
